import { render, staticRenderFns } from "./Revision.vue?vue&type=template&id=535d4a79&scoped=true&"
import script from "./Revision.vue?vue&type=script&lang=js&"
export * from "./Revision.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535d4a79",
  null
  
)

export default component.exports